<template>
  <div>
    <vx-card class="mb-base directors-card">
      <div class="mb-5">
        <vs-table
          ref="table"
          :total="totalDocs"
          :max-items="dataTableParams.limit"
          :data="roomData"
          @sort="handleSort"
          :noDataText="noDataText"
        >
          <div slot="header" class="w-full mb-8">
            <div class="vx-breadcrumb md:block hidden">
              <ul class="flex flex-wrap items-center">
                <li class="inline-flex items-center">
                  <router-link :to="{ name: 'director-learning-centre-list' }"
                    >Manage</router-link
                  >
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>
                <li class="inline-flex items-center">
                  <router-link
                    :to="{
                      name: 'director-learning-centre-view',
                      params: { id: this.centerId }
                    }"
                    >{{ this.centerName }}</router-link
                  >
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>

                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Rooms</a>
                </li>
              </ul>
            </div>
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/4">
                <!-- <div class="card-title">
                  <h2>Early Learning Center</h2>
                </div>-->
              </div>
              <div class="w-full md:w-1/4">
                <vs-button
                  class="w-full md:w-auto float-right"
                  color="primary"
                  type="filled"
                  @click="addRoom"
                  >Add new room</vs-button
                >
              </div>
            </div>
            <div class="flex flex-wrap items-center mt-10 lg:mt-0">
              <div class="w-full lg:w-3/4 flex flex-wrap header-table-row">
                <div class="w-full md:w-1/2">
                  <vs-input
                    class="w-full search-input"
                    icon-pack="feather"
                    icon="icon-search"
                    v-model="dataTableParams.search"
                    placeholder="Search..."
                  />
                </div>
                <div class="w-full md:w-1/4 md:pl-10"></div>
                <div class="w-full md:w-1/4 md:pl-10"></div>
              </div>
              <div class="w-full lg:w-1/4">
                <div class="select-wrapper mt-0 no-border sort-font">
                  <label>Sort By:</label>
                  <vs-select
                    placeholder="Sort By"
                    autocomplete
                    v-model="dataTableParams.sort"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in sortOptions"
                      :clearable="false"
                    />
                  </vs-select>
                </div>
              </div>
            </div>
          </div>

          <template slot="thead">
            <vs-th>Room</vs-th>
            <vs-th>No. Teachers</vs-th>
            <vs-th>No. Children</vs-th>
            <vs-th>Age Group</vs-th>
            <vs-th>Room Location</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">{{ tr.name }}</vs-td>
              <vs-td :data="tr.teacherData">{{ tr.teacherData.length }}</vs-td>
              <vs-td :data="tr.childrenData">{{
                tr.childrenData.length
              }}</vs-td>
              <vs-td :data="tr.ageGroup">{{
                tr.ageGroup && tr.ageGroup.length > 0
                  ? tr.ageGroup[0].ageGroup
                  : "-"
              }}</vs-td>
              <vs-td :data="tr.location">{{ tr.location }}</vs-td>
              <vs-td :data="tr.location">
                <router-link
                  :to="{
                    name: 'room-detail',
                    params: { id: tr._id, centerId: centerId }
                  }"
                  >View</router-link
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="pagination-wrapper">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
                (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded && totalDocs > 0">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      totalDocs: 0,
      awaitingSearch: false,
      dataTableParams: {
        search: "",
        //searchText: "",
        sort: "startDate",
        dir: "asc",
        limit: 5,
        page: 1,
        status: "",
        centerId: this.$route.params.id
      },
      sortOptions: [
        { text: "Name", value: "name" },
        { text: "Location", value: "location" }
      ],

      roomData: [],
      teacherData: [],
      childrenData: [],
      type: this.$route.params.type,

      centerName: null,
      serverResponded: false,
      noDataText: "Currently no room available"
    };
  },
  methods: {
    ...mapActions("room", ["getFilteredDirectorRoomList"]),
    ...mapActions("center", ["getDirectorCenterById"]),
    async fetchRoomList() {
      await this.getFilteredDirectorRoomList(this.dataTableParams).then(res => {
        this.roomData = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;

        if (this.roomData.length == 0 && "" != this.dataTableParams.search) {
          this.noDataText =
            "Cannot find room with search text: '" +
            this.dataTableParams.search +
            "'";
        }
      });
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchRoomList();
    },
    /* handleSearch(searching) {
                this.dataTableParams.search = searching;
                this.dataTableParams.page = 1;
                this.$refs.table.currentx = 1;
                this.fetchRoomList();
            },*/

    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchRoomList();
    },
    addRoom() {
      this.$router.push({
        name: "director-add-room",
        params: { centerId: this.centerId }
      });
    },
    async getCenterDetails(id) {
      this.$vs.loading();
      await this.getDirectorCenterById(id)
        .then(async res => {
          let result = await res.data.data;
          this.centerName = result.name;

          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          //console.error(err)
        });
    }
  },
  created() {
    this.getCenterDetails(this.centerId);

    this.fetchRoomList(this.centerId);
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    centerId() {
      return this.$route.params.id;
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchRoomList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchRoomList();
      }
    },
    "dataTableParams.sort": function(newSort, oldSort) {
      if (newSort !== oldSort) {
        this.dataTableParams.page = 1;
        this.dataTableParams.sort = newSort;
        this.fetchRoomList();
      }
    },
    "dataTableParams.search": function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.dataTableParams.page = 1;
          this.$refs.table.currentx = 1;
          this.fetchRoomList();
          this.awaitingSearch = false;
        }, 500); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  }
};
</script>
